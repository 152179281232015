@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    background-image: url("assets/background/bg-app.png");
    background-size: 100% 100%;
}

html::-webkit-scrollbar {
    display: none;
}

html, body, #root {
    min-height: 100%;
    min-width: 100%;
    overflow-x: hidden;
}
